import { ReactElement, ReactNode } from "react";
import { createElement } from "react";
import { normalize, setupPage } from "csstips";
import {
  base,
  address,
  highlight,
  thicker,
  positions,
  org,
  description,
  list,
  sectionHed,
  highlights,
  time,
  dek,
} from "./styles.css";

const el =
  (tag: string, className: string) =>
  ({ children }: { children: ReactNode[] | ReactNode }): ReactElement =>
    createElement(tag, { className }, children);

export const Base = el("main", base);
export const Address = el("header", address);
export const Highlight = el("b", highlight);
export const Thicker = el("b", thicker);
export const Positions = el("dl", positions);
export const Org = el("dt", org);
export const Description = el("dd", description);
export const List = el("ul", list);
export const SectionHed = el("h2", sectionHed);
export const Highlights = el("p", highlights);
export const Time = el("div", time);
export const Dek = el("p", dek);
export const initLayout = () => {
  normalize();
  setupPage("#app");
};
