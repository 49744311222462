import { createRoot } from "react-dom/client";
import Resume from "./Resume";
import { initLayout } from "./stories/Layout";
import { StrictMode } from "react";

initLayout();

const root = createRoot(document.getElementById("app")!);
root.render(
  <StrictMode>
    <Resume />
  </StrictMode>
);
